import React from "react";
import * as style from "./ContestTemplates.style";
import { classes } from "typestyle";
import { CreateContestTemplate } from "./CreateContestTemplate";
import { ViewContestTemplates } from "./ViewContestTemplates";
import { CreateMatchups } from "./CreateMatchups";
import { CreateMatchupOptions } from "./CreateMatchupOptions";
import { ResultMatchups } from "./ResultMatchups";
import { ReResultMatchups } from "./ReResultMatchups";
import { EditMatchupOption } from "./EditMatchupOption";

export const ContestTemplates = () => {
  const [activeTab, setActiveTab] = React.useState("Create Contest Templates");
  const [matchupTab, setMatchupTab] = React.useState("Matchups");

  return (
    <div className={style.main}>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Create Contest Templates"
              ? style.activeTopNavItem
              : "",
          )}
          onClick={() => setActiveTab("Create Contest Templates")}
        >
          Create Contest Templates
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "View Contest Templates" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("View Contest Templates")}
        >
          View Contest Templates
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Create Matchups" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Create Matchups")}
        >
          Create Matchups and Options
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Result" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Result")}
        >
          Result Matchups
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Resettle" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Resettle")}
        >
          Resettle Matchups
        </div>
      </div>
      <div className={style.title}>{`${
        activeTab === "Create Matchups" ? "" : activeTab
      }`}</div>
      <div
        style={{
          display: activeTab == "Create Contest Templates" ? "block" : "none",
        }}
      >
        <CreateContestTemplate />
      </div>
      <div
        style={{
          display: activeTab == "View Contest Templates" ? "block" : "none",
        }}
      >
        <ViewContestTemplates isOpen={activeTab == "View Contest Templates"} />
      </div>
      {activeTab == "Create Matchups" && (
        <>
          <div className={style.topNav}>
            <div
              className={classes(
                style.topNavItem,
                matchupTab == "Matchups" ? style.activeTopNavItem : "",
              )}
              onClick={() => setMatchupTab("Matchups")}
            >
              Create Matchup
            </div>
            <div
              className={classes(
                style.topNavItem,
                matchupTab == "Matchup Options" ? style.activeTopNavItem : "",
              )}
              onClick={() => setMatchupTab("Matchup Options")}
            >
              Create Matchup Option
            </div>
            <div
              className={classes(
                style.topNavItem,
                matchupTab == "Edit Matchup Option"
                  ? style.activeTopNavItem
                  : "",
              )}
              onClick={() => setMatchupTab("Edit Matchup Option")}
            >
              Edit Matchup Option
            </div>
          </div>
          {matchupTab === "Matchups" ? (
            <CreateMatchups />
          ) : matchupTab === "Matchup Options" ? (
            <CreateMatchupOptions />
          ) : (
            <EditMatchupOption />
          )}
        </>
      )}
      {activeTab == "Result" && <ResultMatchups />}
      {activeTab == "Resettle" && <ReResultMatchups />}
    </div>
  );
};
