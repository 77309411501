import { gql } from "@apollo/client";

export const UPDATE_BUCKET_MUTATION = gql`
  mutation adminUpdateBucket(
    $id: Int!
    $name: String!
    $description: String!
    $selectionWindowStartsAtUtc: DateTime!
    $selectionWindowEndsAtUtc: DateTime!
    $minSelection: Int!
    $maxSelection: Int!
    $preexistingMatchupIds: [Int!]!
  ) {
    adminUpdateBucket(
      id: $id
      name: $name
      description: $description
      selectionWindowStartsAtUtc: $selectionWindowStartsAtUtc
      selectionWindowEndsAtUtc: $selectionWindowEndsAtUtc
      minSelection: $minSelection
      maxSelection: $maxSelection
      preexistingMatchupIds: $preexistingMatchupIds
    ) {
      id
      name
      description
      selectionWindowStartsAtUtc
      selectionWindowEndsAtUtc
      minSelection
      maxSelection
      matchups {
        id
        name
        description
        eventStartTime
        sport
      }
    }
  }
`;
