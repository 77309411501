import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const main = s({
  padding: "12px 24px",
  justifyContent: "center",
});

export const table = s({
  border: "1px solid black",
  margin: "32px 30px 48px 0px",
});

export const tableRow = s({
  width: "100%",
  display: "flex",
  alignItems: "center",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: "4px 0px",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const updateTabs = s({
  display: "flex",
  border: `1px solid black`,
  width: "fit-content",
  margin: "auto",
  marginBottom: 16,
});

export const updateTab = s({
  borderRight: "1px solid black",
  padding: "8px 16px",
  fontSize: 24,
  fontWeight: 500,
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const selectedUpdateTab = s({
  backgroundColor: Styles.colors.softGreen,
});
