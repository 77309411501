export interface ShakeUser {
  id: number;
  email: string;
  externalId: string;
  displayName?: string;
  profileImgUrl?: string;
  accountStatus: string;
  testingAccount: boolean;
  userDate: {
    termsAcknowledgedUtc: Date;
  };
}
