import { gql } from "@apollo/client";

export const RERESULT_MATCHUP_MUTATION = gql`
  mutation adminReResultMatchup($matchupId: Int!, $matchupOptionId: Int!) {
    adminReResultMatchup(
      matchupId: $matchupId
      matchupOptionId: $matchupOptionId
    ) {
      id
      status
      name
    }
  }
`;
