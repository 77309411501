import { Contest, ContestTemplate } from "./contest";
import { ShakeUser } from "./user";

export interface Brand {
  id: number;
  name: string;
  imageUrl: string;
  rewards: Reward[];
}

export interface Reward {
  id: number;
  brandId: number;
  brand: Brand;
  name: string;
  minAmount: number;
  maxAmount: number;
  contestTemplates: ContestTemplate[];
  prizes: Prize[];
}

export interface Prize {
  id: number;
  userId: number;
  user: ShakeUser;
  rewardId: number;
  reward: Reward;
  contestId: number;
  contest: Contest;
  rewardAmount?: number;
  claimed: boolean;
  deliveryEmail?: string;
  delivered: boolean;
  updatedAtUtc: Date;
}
