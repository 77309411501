import * as React from "react";
import * as style from "./MatchupOptionVisualization.style";
import * as Models from "../../models";
import { NewMatchupOptionVisualization } from "../../pages/ContestTemplates/CreateMatchups/CreateMatchups.reducer";

interface ComponentProps {
  matchupOption: Models.Contests.MatchupOption;
  newMatchupOptionVisualization?: NewMatchupOptionVisualization;
  matchupOptionVisualization?: Models.Contests.MatchupOptionVisualization;
}

export const MatchupOptionVisualization: React.FC<ComponentProps> = ({
  matchupOption,
  matchupOptionVisualization,
  newMatchupOptionVisualization,
}) => {
  return newMatchupOptionVisualization ? (
    <div className={style.component}>
      {newMatchupOptionVisualization.newImageInB64 ? (
        <div className={style.imageContainer}>
          <img
            src={newMatchupOptionVisualization.newImageInB64}
            className={style.image}
          />
        </div>
      ) : newMatchupOptionVisualization.initials ? (
        <div
          className={style.initialsContainer}
          style={{
            backgroundColor:
              newMatchupOptionVisualization.initialsBackgroundColor,
            color: newMatchupOptionVisualization.initialsTextColor,
          }}
        >
          <div className={style.initials}>
            {newMatchupOptionVisualization.initials}
          </div>
        </div>
      ) : null}
      <div className={style.label}>
        {!!matchupOptionVisualization?.displayName
          ? matchupOptionVisualization.displayName
          : matchupOption.name}
      </div>
    </div>
  ) : (
    <div className={style.component}>
      {matchupOptionVisualization?.imageUrl ? (
        <div className={style.imageContainer}>
          <img
            src={matchupOptionVisualization.imageUrl}
            className={style.image}
          />
        </div>
      ) : matchupOptionVisualization?.initials ? (
        <div
          className={style.initialsContainer}
          style={{
            backgroundColor: matchupOptionVisualization.initialsBackgroundColor,
            color: matchupOptionVisualization.initialsTextColor,
          }}
        >
          <div className={style.initials}>
            {matchupOptionVisualization.initials}
          </div>
        </div>
      ) : null}
      <div className={style.label}>
        {!!matchupOptionVisualization?.displayName
          ? matchupOptionVisualization.displayName
          : matchupOption.name}
      </div>
    </div>
  );
};
