import { style as s } from "typestyle";

export const app = s({
  minHeight: "100vh",
  backgroundColor: "#fcfcfc",
  display: "flex",
  flexDirection: "row",
});

export const pageContainer = s({
  flex: 1,
});
