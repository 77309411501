import React from "react";
import * as style from "./ViewBulletins.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import * as Models from "../../../models";
import * as Utility from "../../../utility";
import { Icon } from "../../../components";
import { useDispatch } from "react-redux";
import { classes } from "typestyle";
import { toast } from "react-toastify";
import { UpdateBulletin } from "./UpdateBulletin";

export const ViewBulletins: React.FC = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedBulletinId, setSelectedBulletinId] = React.useState<
    number | null
  >(null);
  const [bulletins, setBulletins] = React.useState<Models.Bulletin[]>([]);

  const getBulletins = (skip?: number) => {
    client
      .query({
        query: Query.GET_BULLETINS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        if (res.data.adminGetBulletins) {
          setBulletins(res.data.adminGetBulletins);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get Bulletins");
      });
  };
  React.useEffect(() => {
    getBulletins();
  }, []);

  const selectedBulletin = React.useMemo(() => {
    return bulletins.find((b) => b.id === selectedBulletinId);
  }, [selectedBulletinId]);

  return (
    <div className={style.component}>
      {!!bulletins ? (
        <div className={style.table}>
          {!!bulletins.length ? (
            bulletins.map((bulletin) => (
              <div
                className={classes(
                  style.row,
                  bulletin.id === selectedBulletinId ? style.selectedRow : "",
                )}
                key={bulletin.id}
                onClick={() => {
                  setSelectedBulletinId(bulletin.id);
                }}
              >
                <div className={style.rowItem}>{bulletin.title}</div>
                <div className={style.rowItem}>{bulletin.status}</div>
                <div className={style.rowItem}>
                  <div>
                    <div>
                      {Utility.date.toShortDateAndTimeUTC(
                        new Date(bulletin.viewableStartsAtUtc),
                      )}
                    </div>
                    <div>
                      {Utility.date.toShortDateAndTimeUTC(
                        new Date(bulletin.viewableEndsAtUtc),
                      )}
                    </div>
                  </div>
                </div>
                <div className={style.rowItem}>
                  <div className={style.imageContainer}>
                    <img src={bulletin.imageUrl} className={style.image} />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className={classes(style.row, style.rowItem)}
              style={{ fontWeight: 600 }}
            >
              No Bulletins To Show
            </div>
          )}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
      {!!selectedBulletin && (
        <UpdateBulletin
          bulletin={selectedBulletin}
          onUpdateSuccess={() => {
            setSelectedBulletinId(null);
            getBulletins();
          }}
        />
      )}
    </div>
  );
};
