import React from "react";
import * as style from "./Bulletins.style";
import { classes } from "typestyle";
import { CreateBulletins } from "./CreateBulletins";
import { ViewBulletins } from "./ViewBulletins";

export const Bulletins = () => {
  const [activeTab, setActiveTab] = React.useState("Create");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Bulletins`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "Create" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("Create")}
        >
          Create Bulletin
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "View" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("View")}
        >
          View Bulletins
        </div>
      </div>
      {activeTab == "Create" && <CreateBulletins />}
      {activeTab == "View" && <ViewBulletins />}
    </div>
  );
};
