import { gql } from "@apollo/client";

export const RESULT_MATCHUP_MUTATION = gql`
  mutation adminResultMatchup($matchupId: Int!, $matchupOptionId: Int!) {
    adminResultMatchup(
      matchupId: $matchupId
      matchupOptionId: $matchupOptionId
    ) {
      id
      status
      name
    }
  }
`;
