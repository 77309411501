import { gql } from "@apollo/client";

export const UPDATE_TERMS_LAST_UPDATED_UTC = gql`
  mutation adminUpdateTermsLastUpdatedUtc($termsLastUpdatedUtc: DateTime!) {
    adminUpdateTermsLastUpdatedUtc(termsLastUpdatedUtc: $termsLastUpdatedUtc) {
      id
      termsLastUpdatedUtc
    }
  }
`;
