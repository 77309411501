import { ContestTemplate } from "./contest";

export interface Bulletin {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  adminUpdatedBy: string;
  viewableStartsAtUtc: Date;
  viewableEndsAtUtc: Date;
  status: BulletinStatus;
  title: string;
  content?: string;
  imageUrl?: string;
  forcePopUp: boolean;
  buttonLabel?: string;
  buttonExternalLink?: string;
  buttonContestTemplateId?: number;
  buttonContestTemplate?: ContestTemplate;
  buttonNavigationTarget?: BulletinNavigationTarget;
}

export enum BulletinStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  DISABLED = "DISABLED",
}

export enum BulletinNavigationTarget {
  CONTEST_TEMPLATE = "CONTEST_TEMPLATE",
  GROUP_CREATE = "GROUP_CREATE",
  WALLET = "WALLET",
  CHALLENGES = "CHALLENGES",
  ADD_FRIENDS = "ADD_FRIENDS",
  EXTERNAL_LINK = "EXTERNAL_LINK",
}
