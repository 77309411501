import React from "react";
import * as style from "./Terms.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import * as Utility from "../../utility";
import * as Components from "../../components";
import { toast } from "react-toastify";

export const Terms = () => {
  const client = useApolloClient();
  const [termsLastUpdated, setTermsLastUpdated] = React.useState<Date | null>(
    new Date(),
  );
  const [currentTermsLastUpdated, setCurrentTermsLastUpdated] =
    React.useState<Date | null>(null);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [confirming, setConfirming] = React.useState(false);

  const [updateTermsLastUpdatedUtc, updateTermsLastUpdatedUtcData] =
    useMutation(Mutation.UPDATE_TERMS_LAST_UPDATED_UTC);

  const getCurrentTermsLastUpdatedUtc = () => {
    client
      .query({
        query: Query.GET_TERMS_LAST_UPDATED_UTC,
      })
      .then((res) => {
        setCurrentTermsLastUpdated(
          new Date(res.data.adminTermsLastUpdatedUtc.termsLastUpdatedUtc),
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSubmit = () => {
    if (
      !!currentTermsLastUpdated &&
      !!termsLastUpdated &&
      termsLastUpdated > currentTermsLastUpdated
    ) {
      updateTermsLastUpdatedUtc({
        variables: {
          termsLastUpdatedUtc: termsLastUpdated,
        },
      })
        .then((res) => {
          if (res.data.adminUpdateTermsLastUpdatedUtc) {
            setCurrentTermsLastUpdated(
              new Date(
                res.data.adminUpdateTermsLastUpdatedUtc.termsLastUpdatedUtc,
              ),
            );
            setTermsLastUpdated(null);
            setUpdateOpen(false);
            setConfirming(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Something went wrong here");
        });
    } else {
      toast.error("Must update this value to be later than the current value");
    }
  };

  React.useEffect(() => {
    getCurrentTermsLastUpdatedUtc();
  }, []);

  return (
    <div className={style.main}>
      <div className={style.titleContainer}>
        <div className={style.title}>Terms</div>
      </div>
      <div className={style.lastUpdated}>
        <div>Terms Last Updated At:</div>
        {currentTermsLastUpdated && (
          <div>{Utility.date.toLocalDateTime(currentTermsLastUpdated)}</div>
        )}
      </div>
      {!updateOpen ? (
        <Components.Button
          label="Have new terms been uploaded? Click here"
          onClick={() => setUpdateOpen(true)}
        />
      ) : (
        <div>
          <Components.DateTimePicker
            wrapperClassName={style.input}
            className={style.picker}
            value={termsLastUpdated}
            onChange={(date) => setTermsLastUpdated(date)}
            label="New Terms Last Updated Date and Time"
          />
          {!confirming ? (
            <Components.Button
              label="Update"
              onClick={() => setConfirming(true)}
            />
          ) : (
            <>
              <div className={style.warning}>
                Are you sure you want to update? This will require all users to
                accept terms again
              </div>
              <Components.Button label="Confirm" onClick={onSubmit} />
            </>
          )}
        </div>
      )}
    </div>
  );
};
