import { media, style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s(
  {
    padding: 6,
    maxWidth: 40,
    maxHeight: 32,
    borderRadius: 4,
  },
  media(
    { minWidth: Style.screensize.mdMin },
    {
      backgroundColor: "#ea7d4c",
    },
  ),
);
