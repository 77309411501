import * as Styles from "../../styles";
import { style as s, media } from "typestyle";

export const header = s(
  {
    display: "none",
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      display: "flex",
      backgroundColor: "#F5F5F5",
      padding: "12px 0",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  ),
);

export const user = s({
  padding: "0 0 0 12px",
});

export const logoContainer = s({
  padding: "8px 0px 0px 12px",
});

export const route = s({
  padding: "12px 0px 12px 8px",
  color: Styles.colors.black2,
  textTransform: "uppercase",
  textDecoration: "none",
  transition: "all 0.4s ease",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.white,
      cursor: "pointer",
    },
  },
});

export const activeRoute = s({
  backgroundColor: Styles.colors.white,
  $nest: {
    "&:hover": {
      cursor: "default",
    },
  },
});

export const icon = s({
  height: 16,
  marginBottom: "-1px",
});

export const onboardingImage = s({
  width: 260,
  margin: "148px 0px 0px 0px",
  rotate: "-12deg",
});
