import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const main = s({
  padding: "12px 24px",
  justifyContent: "center",
});

export const picker = s({
  marginBottom: 16,
  width: "40%",
});

export const input = s({
  marginBottom: 16,
  width: "90%",
});

export const textInput = s({ width: "100%" });

export const checkboxRow = s({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginLeft: -12,
});

export const submit = s({
  marginBottom: 12,
});

export const error = s({
  color: Styles.colors.error,
  marginBottom: 12,
  fontSize: 14,
  fontWeight: 500,
});

export const topNav = s({
  width: "fit-content",
  margin: "auto",
  border: `1px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "stretch",
  marginBottom: 16,
});

export const topNavItem = s({
  flex: 1,
  textAlign: "center",
  padding: 12,
  borderRight: "1px solid black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});

export const modalContainer = s({
  position: "absolute",
  inset: 80,
  backgroundColor: "white",
  border: "1px solid black",
  zIndex: 10,
  overflowY: "auto",
});

export const modalHeaderContainer = s({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 16px",
});

export const modalHeader = s({
  textAlign: "center",
  fontSize: Styles.text.fontSize.xl,
});

export const closeIcon = s({
  width: 48,
  height: 48,
  $nest: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export const table = s({
  border: "1px solid black",
  flexDirection: "row",
  flex: 1,
  margin: 16,
});

export const row = s({
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
  flex: 1,
  padding: "8px 16px",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 2,
  // padding: "4px 0px",
});
