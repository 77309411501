import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * CONTESTS
   */
  GET_CONTEST_TEMPLATES_SUCCESS = "CONTESTS/GET_CONTEST_TEMPLATES_SUCCESS",
  GET_MATCHUPS_SUCCESS = "CONTESTS/GET_MATCHUPS_SUCCESS",
  GET_MATCHUP_OPTIONS_SUCCESS = "CONTESTS/GET_MATCHUP_OPTIONS_SUCCESS",
  CREATE_MATCHUP_OPTION_SUCCESS = "CONTESTS/CREATE_MATCHUP_OPTION_SUCCESS",
  CREATE_MATCHUP_SUCCESS = "CONTESTS/CREATE_MATCHUP_SUCCESS",
  BUCKET_UPDATE_SUCCESS = "CONTESTS/BUCKET_UPDATE_SUCCESS",
  UPDATE_MATCHUP_OPTION_DEFAULT_VISUALIZATION_SUCCESS = "CONTESTS/UPDATE_MATCHUP_OPTION_DEFAULT_VISUALIZATION_SUCCESS",
}

export const getContestTemplatesSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_CONTEST_TEMPLATES_SUCCESS,
  {
    contestTemplates: Models.Contests.ContestTemplate[];
  }
> = ActionModels.createActionCreator(ActionTypes.GET_CONTEST_TEMPLATES_SUCCESS);

export const getMatchupsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_MATCHUPS_SUCCESS,
  {
    matchups: Models.Contests.Matchup[];
  }
> = ActionModels.createActionCreator(ActionTypes.GET_MATCHUPS_SUCCESS);

export const getMatchupOptionsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_MATCHUP_OPTIONS_SUCCESS,
  {
    matchupOptions: Models.Contests.MatchupOption[];
  }
> = ActionModels.createActionCreator(ActionTypes.GET_MATCHUP_OPTIONS_SUCCESS);

export const createMatchupOptionSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.CREATE_MATCHUP_OPTION_SUCCESS,
  Models.Contests.MatchupOption
> = ActionModels.createActionCreator(ActionTypes.CREATE_MATCHUP_OPTION_SUCCESS);

export const updateMatchupOptionDefaultVisualizationSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_MATCHUP_OPTION_DEFAULT_VISUALIZATION_SUCCESS,
  Models.Contests.MatchupOption
> = ActionModels.createActionCreator(
  ActionTypes.UPDATE_MATCHUP_OPTION_DEFAULT_VISUALIZATION_SUCCESS,
);

export const createMatchupSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.CREATE_MATCHUP_SUCCESS,
  Models.Contests.Matchup
> = ActionModels.createActionCreator(ActionTypes.CREATE_MATCHUP_SUCCESS);

export const bucketUpdateSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.BUCKET_UPDATE_SUCCESS,
  { contestTemplateId: number; bucket: Models.Contests.Bucket }
> = ActionModels.createActionCreator(ActionTypes.BUCKET_UPDATE_SUCCESS);

export type Actions = ReturnType<
  | typeof getContestTemplatesSuccess
  | typeof getMatchupsSuccess
  | typeof getMatchupOptionsSuccess
  | typeof updateMatchupOptionDefaultVisualizationSuccess
  | typeof createMatchupOptionSuccess
  | typeof createMatchupSuccess
  | typeof bucketUpdateSuccess
>;
