import React from "react";
import * as style from "./CreateMatchups.style";
import { classes } from "typestyle";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import * as Components from "../../../components";
import {
  createMatchupsReducer,
  ExistingMatchupOption,
  initialCreateMatchupsState,
} from "./CreateMatchups.reducer";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import InfiniteScroll from "react-infinite-scroll-component";

interface StateProps {
  matchupOptions: Record<number, Models.Contests.MatchupOption> | null;
}

export const CreateMatchupsInternal: React.FC<StateProps> = ({
  matchupOptions,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [hasMoreMatchupOptions, setHasMoreMatchupOptions] =
    React.useState(true);
  const [optionFilter, setOptionFilter] = React.useState("");

  const matchupOptionsArray = React.useMemo(() => {
    return matchupOptions
      ? Object.values(matchupOptions).filter(
          (opt) =>
            !optionFilter ||
            opt.name.toLocaleLowerCase().includes(optionFilter),
        )
      : [];
  }, [matchupOptions, optionFilter]);

  const getMatchupOptions = (skip: number) => {
    client
      .query({
        query: Query.GET_MATCHUP_OPTIONS_QUERY,
        variables: {
          take: 50,
          skip,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchupOptions?.length) {
          dispatch(
            ContestActions.getMatchupOptionsSuccess({
              matchupOptions: res.data.adminMatchupOptions,
            }),
          );
        } else {
          setHasMoreMatchupOptions(false);
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchupOptions(0);
  }, []);

  /**
   * Values
   */
  const [state, localDispatch] = React.useReducer(
    createMatchupsReducer,
    initialCreateMatchupsState,
  );
  const [
    currentMatchupOptionVisualization,
    setCurrentMatchupOptionVisualization,
  ] = React.useState<number | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  React.useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        localDispatch({
          type: "UPDATE_VISUALIZATION",
          payload: {
            matchupOptionId: currentMatchupOptionVisualization ?? -1,
            field: "newImageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFile]);

  React.useEffect(() => {
    setSelectedFile(null);
  }, [currentMatchupOptionVisualization]);

  React.useEffect(() => {
    setHasMoreMatchupOptions(true);
  }, [optionFilter]);

  const [createMatchupMutation, createMatchupMutationStatus] = useMutation(
    Mutation.CREATE_MATCHUP_MUTATION,
  );

  React.useEffect(() => {
    if (state.readyToSubmit) {
      createMatchupMutation({
        variables: {
          name: state.name,
          description: state.description,
          eventStartTime: state.eventStartTime,
          newOptions: [],
          preexistingOptions: Object.values(state.matchupOptions).map(
            (opt) => ({
              matchupOptionId: opt.matchupOptionId,
              existingVisualizationId: !!opt.newVisualization
                ? undefined
                : opt.existingVisualizationId,
              newVisualization: !!opt.newVisualization
                ? {
                    displayName: opt.newVisualization.displayName,
                    description: opt.newVisualization.description,
                    initials: opt.newVisualization.initials,
                    initialsBackgroundColor:
                      opt.newVisualization.initialsBackgroundColor,
                    initialsTextColor: opt.newVisualization.initialsTextColor,
                    newImageInB64: opt.newVisualization.newImageInB64,
                  }
                : undefined,
              sortOrder: opt.sortOrder,
            }),
          ),
        },
      })
        .then((res) => {
          if (res?.data?.adminCreateMatchup) {
            setCurrentMatchupOptionVisualization(null);
            localDispatch({ type: "SUBMIT_SUCCESS" });
            dispatch(
              ContestActions.createMatchupSuccess(res.data.adminCreateMatchup),
            );
            setSelectedFile(null);
          }
        })
        .catch((e) => {
          console.log(JSON.stringify(e));
          localDispatch({
            type: "SUBMIT_FAILURE",
            payload: {
              error: e?.graphQLErrors?.length ? e.graphQLErrors[0].message : "",
            },
          });
        });
    }
  }, [state.readyToSubmit]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        Create Matchup
      </div>
      <div className={style.formWrapper}>
        <div className={style.formSection}>
          <Components.TextInput
            className={style.textInput}
            value={state.name}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_FIELD",
                payload: {
                  field: "name",
                  value: e,
                },
              })
            }
            label="Name"
            error={state.errors["name"]}
            autocomplete="off"
          />
          <Components.TextInput
            className={style.textArea}
            value={state.description}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_FIELD",
                payload: {
                  field: "description",
                  value: e,
                },
              })
            }
            label="Description"
            error={state.errors["description"]}
            autocomplete="off"
            isTextArea={true}
          />
          <Components.DateTimePicker
            wrapperClassName={style.input}
            className={style.textInput}
            value={state.eventStartTime}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_FIELD",
                payload: {
                  field: "eventStartTime",
                  value: e,
                },
              })
            }
            label="Event Start Time"
            error={state.errors["eventStartTime"]}
          />
        </div>
      </div>
      <div className={style.subtitle}>Matchup Options</div>
      {state.errors["matchupOptions"] && (
        <div className={style.error} style={{ textAlign: "center" }}>
          {state.errors["matchupOptions"]}
        </div>
      )}
      <div className={style.matchupOptionsWrapper}>
        <div className={style.matchupOptionsSection}>
          <Components.TextInput
            className={style.textInput}
            value={optionFilter}
            onChange={(e) => setOptionFilter(e.toLocaleLowerCase())}
            label="Filter"
          />
          <div className={style.matchupOptionSelectionWrapper}>
            <InfiniteScroll
              dataLength={matchupOptionsArray.length}
              next={() => getMatchupOptions(matchupOptionsArray.length)}
              hasMore={hasMoreMatchupOptions}
              loader={<Components.Icon.Spinner size={30} />}
            >
              {matchupOptionsArray.map((opt) => (
                <div className={style.matchupOptionWrapper} key={opt.id}>
                  <div style={{ flex: 1 }}>
                    <div
                      className={classes(
                        style.matchupOption,
                        !!state.matchupOptions[opt.id]
                          ? style.selectedMatchupOption
                          : "",
                      )}
                      onClick={() => {
                        if (
                          state.matchupOptions[opt.id] &&
                          currentMatchupOptionVisualization == opt.id
                        ) {
                          setCurrentMatchupOptionVisualization(null);
                        }
                        localDispatch({
                          type: "MATCHUP_OPTION_CLICKED",
                          payload: {
                            matchupOptionId: opt.id,
                            existingVisualizationId:
                              opt.defaultMatchupOptionVisualizationId,
                          },
                        });
                      }}
                    >
                      {opt.name}
                    </div>
                    {!!state.matchupOptions[opt.id] && (
                      <div
                        className={
                          style.matchupOptionVisualizationSelectionWrapper
                        }
                      >
                        <div
                          className={classes(
                            style.matchupOptionVisualizationOption,
                            !state.matchupOptions[opt.id].newVisualization
                              ? style.selectedMatchupOptionVisualizationOption
                              : "",
                          )}
                          onClick={() =>
                            localDispatch({
                              type: "MATCHUP_OPTION_REMOVE_VISUALIZATION",
                              payload: {
                                matchupOptionId: opt.id,
                              },
                            })
                          }
                        >
                          Default Visualization
                        </div>
                        <div
                          className={classes(
                            style.matchupOptionVisualizationOption,
                            !!state.matchupOptions[opt.id].newVisualization
                              ? style.selectedMatchupOptionVisualizationOption
                              : "",
                          )}
                          onClick={() =>
                            localDispatch({
                              type: "MATCHUP_OPTION_ADD_VISUALIZATION",
                              payload: {
                                matchupOptionId: opt.id,
                              },
                            })
                          }
                        >
                          Custom Visualization
                        </div>
                      </div>
                    )}
                  </div>
                  {!!state.matchupOptions[opt.id] ? (
                    <Components.Icon.CircleArrowRight
                      className={classes(
                        style.circleRightArrowIcon,
                        currentMatchupOptionVisualization == opt.id
                          ? style.selectedCircleRightArrowIcon
                          : "",
                      )}
                      size={24}
                      fill="white"
                      stroke="black"
                      onClick={() =>
                        setCurrentMatchupOptionVisualization(opt.id)
                      }
                    />
                  ) : (
                    <div style={{ width: 24 }} />
                  )}
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <Components.Button
            className={style.moreButton}
            disabled={!hasMoreMatchupOptions}
            onClick={() => getMatchupOptions(matchupOptionsArray.length)}
            label="Fetch More"
          />
        </div>
        <div className={style.matchupOptionsSection}>
          {!!currentMatchupOptionVisualization && !!matchupOptions && (
            <>
              <Components.MatchupOptionVisualization
                matchupOption={
                  matchupOptions[currentMatchupOptionVisualization]
                }
                newMatchupOptionVisualization={
                  state.matchupOptions[currentMatchupOptionVisualization]
                    .newVisualization
                }
                matchupOptionVisualization={
                  matchupOptions[currentMatchupOptionVisualization]
                    .defaultMatchupOptionVisualization
                }
              />
              <div className={style.subtitle} style={{ fontSize: 20 }}>
                {matchupOptions[currentMatchupOptionVisualization]?.name}{" "}
                Visualization
              </div>
              <Components.TextInput
                className={style.textInput}
                value={
                  state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization?.displayName ??
                  matchupOptions[currentMatchupOptionVisualization]
                    .defaultMatchupOptionVisualization.displayName
                }
                disabled={
                  !state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_VISUALIZATION",
                    payload: {
                      matchupOptionId: currentMatchupOptionVisualization,
                      field: "displayName",
                      value: e,
                    },
                  })
                }
                label="Display Name"
                error={state.errors["displayName"]}
                autocomplete="off"
              />
              <Components.TextInput
                className={style.textInput}
                value={
                  state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization?.initials ??
                  matchupOptions[currentMatchupOptionVisualization]
                    .defaultMatchupOptionVisualization.initials
                }
                disabled={
                  !state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization
                }
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_VISUALIZATION",
                    payload: {
                      matchupOptionId: currentMatchupOptionVisualization,
                      field: "initials",
                      value: e,
                    },
                  })
                }
                label="Initials"
                error={state.errors["initials"]}
                autocomplete="off"
              />
              {!!state.matchupOptions[currentMatchupOptionVisualization]
                ?.newVisualization ? (
                <div className={style.colorRow}>
                  <HexAlphaColorPicker
                    className={style.colorBox}
                    color={
                      state.matchupOptions[currentMatchupOptionVisualization]
                        ?.newVisualization?.initialsBackgroundColor ??
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization
                        .initialsBackgroundColor
                    }
                    onChange={(e) =>
                      localDispatch({
                        type: "UPDATE_VISUALIZATION",
                        payload: {
                          matchupOptionId: currentMatchupOptionVisualization,
                          field: "initialsBackgroundColor",
                          value: e,
                        },
                      })
                    }
                    prefix="#"
                  />
                  <HexColorInput
                    color={
                      state.matchupOptions[currentMatchupOptionVisualization]
                        ?.newVisualization?.initialsBackgroundColor ??
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization
                        .initialsBackgroundColor
                    }
                    onChange={(e) =>
                      localDispatch({
                        type: "UPDATE_VISUALIZATION",
                        payload: {
                          matchupOptionId: currentMatchupOptionVisualization,
                          field: "initialsBackgroundColor",
                          value: e,
                        },
                      })
                    }
                    alpha
                  />
                  <div className={style.label}>Initials Background Color</div>
                  {state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization?.errors["initialsBackgroundColor"] && (
                    <div className={style.error}>
                      {
                        state.matchupOptions[currentMatchupOptionVisualization]
                          .newVisualization?.errors["initialsBackgroundColor"]
                      }
                    </div>
                  )}
                </div>
              ) : (
                <div className={style.colorRow}>
                  <div>initials background color: </div>
                  <div
                    style={{
                      backgroundColor:
                        matchupOptions[currentMatchupOptionVisualization]
                          .defaultMatchupOptionVisualization
                          .initialsBackgroundColor,
                      padding: 4,
                    }}
                  >
                    {
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization
                        .initialsBackgroundColor
                    }
                  </div>
                </div>
              )}
              {!!state.matchupOptions[currentMatchupOptionVisualization]
                ?.newVisualization ? (
                <div className={style.colorRow}>
                  <HexAlphaColorPicker
                    className={style.colorBox}
                    color={
                      state.matchupOptions[currentMatchupOptionVisualization]
                        ?.newVisualization?.initialsTextColor ??
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization.initialsTextColor
                    }
                    onChange={(e) =>
                      localDispatch({
                        type: "UPDATE_VISUALIZATION",
                        payload: {
                          matchupOptionId: currentMatchupOptionVisualization,
                          field: "initialsTextColor",
                          value: e,
                        },
                      })
                    }
                    prefix="#"
                  />
                  <HexColorInput
                    color={
                      state.matchupOptions[currentMatchupOptionVisualization]
                        ?.newVisualization?.initialsTextColor ??
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization.initialsTextColor
                    }
                    onChange={(e) =>
                      localDispatch({
                        type: "UPDATE_VISUALIZATION",
                        payload: {
                          matchupOptionId: currentMatchupOptionVisualization,
                          field: "initialsTextColor",
                          value: e,
                        },
                      })
                    }
                    alpha
                  />
                  <div className={style.label}>Text Color</div>
                  {state.matchupOptions[currentMatchupOptionVisualization]
                    ?.newVisualization?.errors["initialsTextColor"] && (
                    <div className={style.error}>
                      {
                        state.matchupOptions[currentMatchupOptionVisualization]
                          .newVisualization?.errors["initialsTextColor"]
                      }
                    </div>
                  )}
                </div>
              ) : (
                <div className={style.colorRow}>
                  <div>initials text color: </div>
                  <div
                    style={{
                      backgroundColor:
                        matchupOptions[currentMatchupOptionVisualization]
                          .defaultMatchupOptionVisualization.initialsTextColor,
                      padding: 4,
                    }}
                  >
                    {
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization.initialsTextColor
                    }
                  </div>
                </div>
              )}
              {!!state.matchupOptions[currentMatchupOptionVisualization]
                ?.newVisualization ? (
                <>
                  <div className={style.input}>
                    <input
                      type="file"
                      name="image"
                      onChange={(e) =>
                        setSelectedFile(e!.currentTarget!.files![0])
                      }
                      accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                    />
                    {state.matchupOptions[currentMatchupOptionVisualization]
                      .newVisualization?.errors["newImageInB64"] && (
                      <div className={style.error}>
                        {
                          state.matchupOptions[
                            currentMatchupOptionVisualization
                          ].newVisualization?.errors["newImageInB64"]
                        }
                      </div>
                    )}
                  </div>
                  <div className={style.imageContainer}>
                    <img
                      src={
                        state.matchupOptions[currentMatchupOptionVisualization]
                          .newVisualization?.newImageInB64
                      }
                      alt="prop image"
                      className={style.image}
                    />
                  </div>
                  {state.matchupOptions[currentMatchupOptionVisualization]
                    .newVisualization?.newImageInB64 && (
                    <div
                      onClick={() => {
                        setSelectedFile(null);
                        localDispatch({
                          type: "UPDATE_VISUALIZATION",
                          payload: {
                            matchupOptionId: currentMatchupOptionVisualization,
                            field: "newImageInB64",
                            value: "",
                          },
                        });
                      }}
                      className={style.removeImageButton}
                    >
                      Remove image
                    </div>
                  )}
                </>
              ) : (
                <div className={style.imageContainer}>
                  <img
                    src={
                      matchupOptions[currentMatchupOptionVisualization]
                        .defaultMatchupOptionVisualization.imageUrl
                    }
                    alt="prop image"
                    className={style.image}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {matchupOptions && (
        <div>
          <div className={style.subtitle} style={{ paddingTop: 48 }}>
            Sort Order
          </div>
          <div style={{ padding: `0px 24px 24px`, display: "flex", gap: 12 }}>
            {Object.values<ExistingMatchupOption>(state.matchupOptions)
              .sort((a: ExistingMatchupOption, b: ExistingMatchupOption) =>
                a.sortOrder > b.sortOrder ? 1 : -1,
              )
              .map((opt) => (
                <div
                  style={{ border: "1px solid black", padding: "4px 8px" }}
                >{`${opt.sortOrder}: ${
                  matchupOptions[opt.matchupOptionId].name
                }`}</div>
              ))}
          </div>
        </div>
      )}
      <Components.Button
        className={style.button}
        onClick={() => localDispatch({ type: "SUBMIT" })}
        label="Submit"
        inProgress={state.readyToSubmit}
      />
      {state.errors["submit"] && (
        <div className={style.error}>{state.errors["submit"]}</div>
      )}
    </div>
  );
};

export const CreateMatchups = connect((state: RootState) => ({
  matchupOptions: state.contests.matchupOptions,
}))(CreateMatchupsInternal);
