import { gql } from "@apollo/client";

export const CREATE_BULLETIN_MUTATION = gql`
  mutation adminCreateBulletin(
    $viewableStartsAtUtc: DateTime!
    $viewableEndsAtUtc: DateTime!
    $forcePopUp: Boolean!
    $title: String!
    $content: String
    $imageB64: String
    $imageUrl: String
    $buttonLabel: String
    $buttonNavigationTarget: BulletinButtonTarget
    $buttonExternalLink: String
    $buttonContestTemplateId: Int
  ) {
    adminCreateBulletin(
      viewableStartsAtUtc: $viewableStartsAtUtc
      viewableEndsAtUtc: $viewableEndsAtUtc
      forcePopUp: $forcePopUp
      title: $title
      content: $content
      imageB64: $imageB64
      imageUrl: $imageUrl
      buttonLabel: $buttonLabel
      buttonNavigationTarget: $buttonNavigationTarget
      buttonExternalLink: $buttonExternalLink
      buttonContestTemplateId: $buttonContestTemplateId
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      viewableStartsAtUtc
      viewableEndsAtUtc
      status
      title
      content
      imageUrl
      forcePopUp
      buttonLabel
      buttonExternalLink
      buttonContestTemplateId
      buttonContestTemplate {
        id
        name
        status
      }
      buttonNavigationTarget
    }
  }
`;
