import { gql } from "@apollo/client";

export const GET_MATCHUPS_QUERY = gql`
  query adminMatchups(
    $take: Int!
    $skip: Int!
    $eventStartTimeLessThan: DateTime
    $eventStartTimeGreaterThan: DateTime
    $status: MatchupStatus
  ) {
    adminMatchups(
      take: $take
      skip: $skip
      eventStartTimeLessThan: $eventStartTimeLessThan
      eventStartTimeGreaterThan: $eventStartTimeGreaterThan
      status: $status
    ) {
      id
      name
      status
      description
      eventStartTime
      sport
      resultId
      matchupOptions {
        matchupOptionVisualization {
          id
          description
          displayName
          initials
          initialsBackgroundColor
          initialsTextColor
          imageUrl
        }
        matchupOption {
          id
          name
        }
      }
    }
  }
`;
