import { gql } from "@apollo/client";

export const ADD_WALLET_TRANSACTION_MUTATION = gql`
  mutation adminAddWalletTransaction(
    $userId: Int!
    $amount: Int!
    $refId: String!
    $currencyType: CurrencyType!
    $reason: String
  ) {
    adminAddWalletTransaction(
      userId: $userId
      amount: $amount
      refId: $refId
      currencyType: $currencyType
      reason: $reason
    ) {
      success
    }
  }
`;
