import { gql } from "@apollo/client";

export const UPDATE_BULLETIN_MUTATION = gql`
  mutation adminUpdateBulletin(
    $bulletinId: Int!
    $viewableStartsAtUtc: DateTime
    $viewableEndsAtUtc: DateTime
    $forcePopUp: Boolean!
    $title: String
    $status: BulletinStatus
    $content: String
    $imageB64: String
    $imageUrl: String
    $buttonLabel: String
    $buttonNavigationTarget: BulletinButtonTarget
    $buttonExternalLink: String
    $buttonContestTemplateId: Int
  ) {
    adminUpdateBulletin(
      bulletinId: $bulletinId
      viewableStartsAtUtc: $viewableStartsAtUtc
      viewableEndsAtUtc: $viewableEndsAtUtc
      forcePopUp: $forcePopUp
      title: $title
      status: $status
      content: $content
      imageB64: $imageB64
      imageUrl: $imageUrl
      buttonLabel: $buttonLabel
      buttonNavigationTarget: $buttonNavigationTarget
      buttonExternalLink: $buttonExternalLink
      buttonContestTemplateId: $buttonContestTemplateId
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      viewableStartsAtUtc
      viewableEndsAtUtc
      status
      title
      content
      imageUrl
      forcePopUp
      buttonLabel
      buttonExternalLink
      buttonContestTemplateId
      buttonContestTemplate {
        id
        name
        status
      }
      buttonNavigationTarget
    }
  }
`;
