import * as Styles from "../../styles";
import { style as s, media } from "typestyle";

export const main = s({
  padding: "12px 24px",
  justifyContent: "center",
});

export const topNav = s(
  {
    flex: 1,
    border: `1px solid black`,
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 16,
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      flexWrap: "wrap",
    },
  ),
);

export const topNavItem = s(
  {
    flex: 1,
    textAlign: "center",
    padding: 4,
    fontSize: 10,
    borderRight: "1px solid black",
    $nest: {
      "&:last-child": {
        borderRightWidth: 0,
      },
    },
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      padding: 12,
      fontSize: 16,
    },
  ),
);

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});

export const title = s({
  textAlign: "center",
  fontSize: 40,
  fontWeight: 500,
});
