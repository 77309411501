import * as Styles from "../../../styles";
import { style as s } from "typestyle";
export {
  picker,
  input,
  textInput,
  checkboxRow,
} from "../CreateContestTemplate/CreateContestTemplate.style";
export {
  colorRow,
  colorBox,
  label,
  error,
  removeImageButton,
  imageContainer,
  image,
} from "../CreateMatchupOptions/CreateMatchupOptions.style";
export { title } from "../ContestTemplates.style";

export const main = s({
  padding: "12px 24px",
  marginBottom: 48,
  justifyContent: "center",
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
});

export const textArea = s({
  height: 150,
  width: "100%",
  paddingTop: 8,
});

export const button = s({
  margin: "auto",
  marginBottom: 12,
  width: 800,
});

export const moreButton = s({
  margin: "auto",
  marginBottom: 12,
  width: 250,
});

export const formWrapper = s({
  display: "flex",
  justifyContent: "space-between",
});

export const formSection = s({
  flex: 1,
  paddingRight: 8,
  marginRight: 8,
  borderRight: `1px dotted ${Styles.colors.darkTranslucent200}`,
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const subtitle = s({
  fontSize: 24,
  fontWeight: 500,
  marginBottom: 12,
  textAlign: "center",
});

export const matchupOptionSelectionWrapper = s({
  padding: 12,
  borderBottom: "1px solid gray",
  marginBottom: 12,
  maxHeight: 250,
  overflowY: "scroll",
});

export const matchupOptionsWrapper = s({
  display: "flex",
  justifyContent: "space-between",
});

export const matchupOptionsSection = s({
  flex: 1,
  paddingRight: 8,
  marginRight: 8,
  borderRight: `1px dotted ${Styles.colors.darkTranslucent200}`,
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const matchupOptionWrapper = s({
  display: "flex",
  alignItems: "center",
  gap: 12,
});

export const matchupOption = s({
  flex: 1,
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
  borderRadius: 4,
  marginBottom: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 8,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.softYellow,
    },
  },
  cursor: "pointer",
});

export const selectedMatchupOption = s({
  backgroundColor: Styles.colors.yellow,
  marginBottom: 0,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.yellow,
    },
  },
});

export const matchupOptionVisualizationSelectionWrapper = s({
  border: `1px solid ${Styles.colors.darkTranslucent200}`,
  display: "flex",
  alignItems: "stretch",
  marginBottom: 12,
  borderRadius: 4,
});

export const matchupOptionVisualizationOption = s({
  flex: 1,
  padding: 4,
  fontSize: 14,
  cursor: "pointer",
  textAlign: "center",
});

export const selectedMatchupOptionVisualizationOption = s({
  backgroundColor: Styles.colors.pink,
  cursor: "auto",
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.pink,
    },
  },
});

export const circleRightArrowIcon = s({
  cursor: "pointer",
  $nest: {
    "&:hover": {
      $nest: {
        svg: {
          fill: Styles.colors.yellow,
        },
      },
    },
  },
});

export const selectedCircleRightArrowIcon = s({
  $nest: {
    svg: {
      fill: Styles.colors.yellow,
    },
  },
});
