import * as React from "react";
import * as style from "./MobileHeader.style";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo-with-name.png";
import onboardingImage from "../../../assets/Onboarding.png";
import UserIcon from "../../../assets/icons/User.png";
import GridIcon from "../../../assets/icons/Grid.png";
import PlayIcon from "../../../assets/icons/Play.png";
import TrophyIcon from "../../../assets/icons/Trophy.png";
import GiftIcon from "../../../assets/icons/Gift.png";
import DocumentIcon from "../../../assets/icons/Document.png";
import { Burger } from "../Burger";

export const MobileHeader: React.FC = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className={style.burgerContainer}>
          <Burger
            color="black"
            isOpen={isOpen}
            onClick={() => setIsOpen((val) => !val)}
          />
          <div className={style.logoContainer}>
            <img src={logo} className={style.icon} />
          </div>
          <div>
            <SignedOut>
              <SignInButton />
            </SignedOut>
            <SignedIn>
              <UserButton />
              <JwtTracker />
            </SignedIn>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={style.header}>
          <Link
            to="/"
            className={`${style.route} ${
              pathname == "/" ? style.activeRoute : ""
            }`}
          >
            <img src={GridIcon} className={style.icon} /> Home
          </Link>
          <Link
            to="/users"
            className={`${style.route} ${
              pathname == "/users" ? style.activeRoute : ""
            }`}
          >
            <img src={UserIcon} className={style.icon} /> Users
          </Link>
          <Link
            to="/contests"
            className={`${style.route} ${
              pathname == "/contests" ? style.activeRoute : ""
            }`}
          >
            <img src={PlayIcon} className={style.icon} /> Contests
          </Link>
          <Link
            to="/rewards"
            className={`${style.route} ${
              pathname == "/rewards" ? style.activeRoute : ""
            }`}
          >
            <img src={TrophyIcon} className={style.icon} /> Rewards
          </Link>
          <Link
            to="/prizes"
            className={`${style.route} ${
              pathname == "/prizes" ? style.activeRoute : ""
            }`}
          >
            <img src={GiftIcon} className={style.icon} /> Prizes
          </Link>
          <Link
            to="/terms"
            className={`${style.route} ${
              pathname == "/terms" ? style.activeRoute : ""
            }`}
          >
            <img src={DocumentIcon} className={style.icon} /> Terms
          </Link>
          <div className={style.onboardingImageContainer}>
            <img
              src={onboardingImage}
              className={style.onboardingImage}
              width={200}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const JwtTracker: React.FC = () => {
  const { getToken } = useAuth();
  const { user } = useUser();
  React.useEffect(() => {
    if (user) {
      getToken({ template: "jwt_with_email" }).then((res) => {
        console.log("JWT Token::\n", res);
        if (res && !!localStorage) {
          localStorage.setItem("userJwt", res);
        }
      });
    }
  }, [user, localStorage]);

  return null;
};
