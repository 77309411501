import { gql } from "@apollo/client";

export const GET_BULLETINS_QUERY = gql`
  query adminGetBulletins($take: Int!, $skip: Int!) {
    adminGetBulletins(take: $take, skip: $skip) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      viewableStartsAtUtc
      viewableEndsAtUtc
      status
      title
      content
      imageUrl
      forcePopUp
      buttonLabel
      buttonExternalLink
      buttonContestTemplateId
      buttonContestTemplate {
        id
        name
        status
      }
      buttonNavigationTarget
    }
  }
`;
