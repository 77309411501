import React from "react";
import * as style from "./CreateBulletins.style";
import { classes } from "typestyle";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../store/actions";
import { Checkbox, MenuItem, Select } from "@mui/material";
import {
  ContestTemplate,
  ContestTemplateStatus,
} from "../../../models/contest";

export const CreateBulletins = () => {
  const client = useApolloClient();

  const [viewableStartsAtUtc, setViewableStartsAtUtc] =
    React.useState<Date | null>(null);
  const [viewableEndsAtUtc, setViewableEndsAtUtc] = React.useState<Date | null>(
    null,
  );
  const [title, setTitle] = React.useState<string>("");
  const [content, setContent] = React.useState<string>("");
  const [imageInB64, setImageInB64] = React.useState<string>("");
  const [forcePopUp, setForcePopUp] = React.useState(false);
  const [hasButton, setHasButton] = React.useState(false);
  const [buttonLabel, setButtonLabel] = React.useState("");
  const [buttonNavigationTarget, setButtonNavigationTarget] =
    React.useState<Models.BulletinNavigationTarget>(
      Models.BulletinNavigationTarget.ADD_FRIENDS,
    );
  const [buttonExternalLink, setButtonExternalLink] = React.useState("");
  const [buttonContestTemplateId, setButtonContestTemplateId] =
    React.useState<string>("");

  const [contestTemplates, setContestTemplate] = React.useState<
    ContestTemplate[]
  >([]);
  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  /** Image  */
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  React.useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        setImageInB64(fileReader!.result!.toString());
      };
    }
  }, [selectedFile]);

  const [createBulletinMutation, createBulletinMutationData] = useMutation(
    Mutation.CREATE_BULLETIN_MUTATION,
  );

  const getContestTemplates = () => {
    client
      .query({
        query: Query.GET_CONTEST_TEMPLATES_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          take: 20,
          skip: 0,
          statuses: [ContestTemplateStatus.PUBLISHED],
        },
      })
      .then((res) => {
        if (res?.data?.adminContestTemplates?.length) {
          setContestTemplate(res.data.adminContestTemplates);
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    if (
      buttonNavigationTarget ===
      Models.BulletinNavigationTarget.CONTEST_TEMPLATE
    ) {
      getContestTemplates();
    }
  }, [buttonNavigationTarget]);

  const onSubmit = () => {
    let canSubmit = true;
    if (!title || !viewableEndsAtUtc || !viewableStartsAtUtc) {
      setSubmitError("Please include a title and viewable start and end times");
      canSubmit = false;
    } else if (viewableEndsAtUtc < viewableStartsAtUtc) {
      setSubmitError("viewable ends at must be after viewable starts at");
      canSubmit = false;
    } else if (new Date(viewableEndsAtUtc) < new Date()) {
      setSubmitError("viewable ends at must be in the future");
      canSubmit = false;
    } else if (!!hasButton) {
      if (!buttonLabel || !buttonNavigationTarget) {
        setSubmitError("Button must have a label and a target");
        canSubmit = false;
      } else if (
        buttonNavigationTarget ===
          Models.BulletinNavigationTarget.EXTERNAL_LINK &&
        !buttonExternalLink
      ) {
        setSubmitError(
          "If button is of type external link, you must enter an external link",
        );
        canSubmit = false;
      } else if (
        buttonNavigationTarget ===
          Models.BulletinNavigationTarget.CONTEST_TEMPLATE &&
        !buttonContestTemplateId
      ) {
        setSubmitError(
          "If button navigation is type CONTEST_TEMPLATE, you must select a contest template",
        );
        canSubmit = false;
      } else if (
        buttonNavigationTarget ===
          Models.BulletinNavigationTarget.CONTEST_TEMPLATE &&
        !!buttonContestTemplateId
      ) {
        const template = contestTemplates.find(
          (t) => t.id === Number.parseInt(buttonContestTemplateId),
        );
        if (
          !!template &&
          (template.availableEndsAtUtc < new Date(viewableEndsAtUtc) ||
            template.availableStartsAtUtc > new Date(viewableStartsAtUtc))
        ) {
          setSubmitError(
            "bulletin needs to start after contest template, and end before contest template. (that way the link is never dead)",
          );
          canSubmit = false;
        }
      }
    }
    if (canSubmit) {
      setInProgress(true);
      setSubmitError("");
      let variables: any = {
        title,
        content,
        viewableStartsAtUtc,
        viewableEndsAtUtc,
        forcePopUp,
        imageB64: !!imageInB64 ? imageInB64 : undefined,
      };
      if (!!hasButton) {
        variables["buttonLabel"] = buttonLabel;
        variables["buttonNavigationTarget"] = buttonNavigationTarget;
        if (
          buttonNavigationTarget ===
          Models.BulletinNavigationTarget.EXTERNAL_LINK
        ) {
          variables["buttonExternalLink"] = buttonExternalLink;
        } else if (
          buttonNavigationTarget ===
          Models.BulletinNavigationTarget.CONTEST_TEMPLATE
        ) {
          variables["buttonContestTemplateId"] = Number.parseInt(
            buttonContestTemplateId,
          );
        }
      }
      createBulletinMutation({
        variables,
      })
        .then((res) => {
          if (res.data.adminCreateBulletin) {
            setInProgress(false);
            setTitle("");
            setContent("");
            setImageInB64("");
            setViewableEndsAtUtc(null);
            setViewableStartsAtUtc(null);
            setForcePopUp(false);
            setHasButton(false);
            setButtonLabel("");
            setButtonNavigationTarget(
              Models.BulletinNavigationTarget.ADD_FRIENDS,
            );
            setButtonExternalLink("");
            setButtonContestTemplateId("");
            setSelectedFile(null);
            toast.success("Bulletin Created as Draft");
          } else {
            toast.error(`Unable to create bulletin: ${res.errors?.toString()}`);
          }
        })
        .catch((e) => {
          setInProgress(false);
          console.log(e);
        });
    }
  };
  return (
    <div className={style.component}>
      <Components.TextInput
        className={style.textInput}
        value={title}
        onChange={setTitle}
        label="Title"
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={content}
        onChange={setContent}
        label="content (subtext)"
        autocomplete="off"
      />
      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={viewableStartsAtUtc}
        onChange={(date) => {
          setViewableStartsAtUtc(date);
        }}
        label="Bulletin is in app starting at"
      />
      <Components.DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={viewableEndsAtUtc}
        onChange={(date) => setViewableEndsAtUtc(date)}
        label="Bulletin is in app ending at"
      />
      <div className={style.checkboxRow}>
        <Checkbox
          checked={forcePopUp}
          onChange={(e) => setForcePopUp(e.target.checked)}
        />
        <div>Force Pop Up</div>
      </div>
      <div className={style.checkboxRow}>
        <Checkbox
          checked={hasButton}
          onChange={(e) => setHasButton(e.target.checked)}
        />
        <div>Button</div>
      </div>
      {hasButton && (
        <>
          <Components.TextInput
            className={style.textInput}
            value={buttonLabel}
            onChange={setButtonLabel}
            label="Button Label"
          />
          <div
            style={{
              marginBottom: 24,
              display: "flex",
              gap: 12,
              alignItems: "center",
            }}
          >
            <Select
              multiple={false}
              value={buttonNavigationTarget}
              onChange={(e) =>
                setButtonNavigationTarget(
                  e.target.value as Models.BulletinNavigationTarget,
                )
              }
              autoWidth={true}
              label="Button Target"
            >
              {Object.values(Models.BulletinNavigationTarget).map((target) => (
                <MenuItem value={target} key={target}>
                  {target}
                </MenuItem>
              ))}
            </Select>
            <div>Button link type</div>
          </div>
          {buttonNavigationTarget ===
            Models.BulletinNavigationTarget.EXTERNAL_LINK && (
            <Components.TextInput
              className={style.textInput}
              value={buttonExternalLink}
              onChange={setButtonExternalLink}
              label="Button external link"
            />
          )}
          {buttonNavigationTarget ===
            Models.BulletinNavigationTarget.CONTEST_TEMPLATE && (
            <Select
              multiple={false}
              value={buttonContestTemplateId}
              onChange={(e) => {
                setButtonContestTemplateId(e.target.value);
              }}
              autoWidth={true}
              label="Contest Template to link to"
            >
              {Object.values(contestTemplates).map((template) => (
                <MenuItem value={template.id} key={template.id}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
      <div
        style={{
          width: "100%",
          paddingBottom: 12,
          marginBottom: 12,
          borderBottom: "1px solid gray",
        }}
      />
      <div className={style.textInput}>
        <input
          type="file"
          name="image"
          onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
          accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
        />
      </div>
      <div className={style.imageContainer}>
        <img src={imageInB64} alt="prop image" className={style.image} />
      </div>
      <div
        onClick={() => {
          setSelectedFile(null);
          setImageInB64("");
        }}
        className={style.removeImageButton}
      >
        Remove image
      </div>
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};
