import { gql } from "@apollo/client";

export const ADMIN_UPDATE_MATCHUP_OPTION_DEFAULT_VIZUALIZATION = gql`
  mutation adminUpdateMatchupOptionDefaultVizualization(
    $matchupOptionId: Int!
    $newVisualization: CreateMatchupOptionVisualizationArgs
  ) {
    adminUpdateMatchupOptionDefaultVizualization(
      matchupOptionId: $matchupOptionId
      newVisualization: $newVisualization
    ) {
      id
      name
      defaultMatchupOptionVisualizationId
      defaultMatchupOptionVisualization {
        id
        displayName
        description
        initials
        initialsBackgroundColor
        initialsTextColor
        imageUrl
      }
    }
  }
`;
