import * as Styles from "../../../styles";
import { media, style as s } from "typestyle";

export const table = s({
  marginTop: "16px",
  border: "1px solid black",
  width: "100%",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const selectedResultRow = s(
  {
    display: "flex",
    fontSize: 10,
    flexDirection: "column",
    $nest: {
      "&:nth-child(even)": {
        backgroundColor: Styles.colors.darkTranslucent100,
      },
    },
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      fontSize: 16,
      flexDirection: "row",
    },
  ),
);

export const rowItemBold = s(
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "4px 0px",
    fontWeight: "bold",
    fontSize: 10,
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      fontSize: 16,
    },
  ),
);

export const rowItem = s(
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "4px 0x",
    fontSize: 10,
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      fontSize: 16,
    },
  ),
);

export const selectedResultButtonContainer = s(
  {
    display: "flex",
    flexDirection: "column",
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      flexDirection: "row",
      fontSize: 16,
    },
  ),
);

export const buttonInTable = s({
  margin: "0px 4px",
});

export const redButton = s({
  backgroundColor: `${Styles.colors.softError} !important`,
  $nest: {
    "&:hover": {
      backgroundColor: `${Styles.colors.error} !important`,
    },
  },
});

export const allMatchupsButton = s({
  width: "auto",
  marginTop: 16,
});

export const modalContainer = s(
  {
    position: "absolute",
    inset: 0,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid black",
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      inset: 140,
    },
  ),
);

export const modalContainerItems = s(
  {
    margin: "16px",
    padding: 16,
    fontSize: 10,
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      fontSize: 16,
    },
  ),
);
