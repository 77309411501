import * as Styles from "../../styles";
import { style as s, media } from "typestyle";

export const burgerContainer = s(
  {
    padding: 12,
    backgroundColor: Styles.colors.darkTranslucent20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      display: "none",
    },
  ),
);

export const header = s(
  {
    display: "flex",
    backgroundColor: "#F5F5F5",
    position: "fixed",
    inset: 0,
    top: 60,
    padding: "12px 0",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 5,
  },
  media(
    { minWidth: Styles.screensize.mdMin },
    {
      display: "none",
    },
  ),
);

export const logoContainer = s({
  padding: "8px 0px 0px 12px",
});

export const route = s({
  padding: "12px 0px 12px 24px",
  color: Styles.colors.black2,
  textTransform: "uppercase",
  textDecoration: "none",
  transition: "all 0.4s ease",
  width: "100vw",
  fontSize: 36,
  zIndex: 6,
  $nest: {
    "&:hover": {
      backgroundColor: Styles.colors.white,
      cursor: "pointer",
    },
  },
});

export const activeRoute = s({
  backgroundColor: Styles.colors.white,
  $nest: {
    "&:hover": {
      cursor: "default",
    },
  },
});

export const icon = s({
  height: 36,
  marginBottom: "-6px",
});

export const onboardingImageContainer = s({
  rotate: "-12deg",
  position: "absolute",
  bottom: 24,
  left: 0,
  right: 0,
});

export const onboardingImage = s({
  width: "100vw",
  zIndex: 7,
});
