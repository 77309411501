import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const table = s({
  marginTop: "16px",
  border: "1px solid black",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItemBold = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: "4px 0px",
  fontWeight: "bold",
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: "4px 0px",
});

export const buttonInTable = s({
  margin: "0px 4px",
});

export const redButton = s({
  backgroundColor: `${Styles.colors.softError} !important`,
  $nest: {
    "&:hover": {
      backgroundColor: `${Styles.colors.error} !important`,
    },
  },
});

export const modalContainer = s({
  position: "absolute",
  inset: 140,
  backgroundColor: "#FFFFFF",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid black",
});

export const modalContainerItems = s({
  margin: "16px",
});
