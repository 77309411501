import { gql } from "@apollo/client";

export const GET_TERMS_LAST_UPDATED_UTC = gql`
  query adminTermsLastUpdatedUtc {
    adminTermsLastUpdatedUtc {
      id
      termsLastUpdatedUtc
      adminUpdatedBy
    }
  }
`;
