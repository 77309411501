import * as Styles from "../../styles";
import { style as s } from "typestyle";

export const main = s({
  padding: "48px 96px",
});

export const titleContainer = s({
  display: "flex",
  justifyContent: "center",
  padding: 24,
});

export const title = s({
  textAlign: "center",
  fontSize: 40,
  fontWeight: 600,
  marginBottom: 24,
});

export const lastUpdated = s({
  display: "flex",
  gap: 16,
  fontSize: 24,
  fontWeight: 500,
  margin: "auto",
  width: "fit-content",
  marginBottom: 64,
});

export const input = s({
  width: "100%",
  marginBottom: 12,
});

export const picker = s({
  width: "100%",
});

export const warning = s({
  fontSize: 20,
  fontWeight: 700,
  textAlign: "center",
  marginBottom: 12,
});
