import * as Styles from "../../../../styles";
import { style as s } from "typestyle";

export const main = s({
  padding: "12px 24px",
  justifyContent: "center",
});

export const picker = s({
  marginBottom: 16,
  width: "40%",
});

export const input = s({
  marginBottom: 16,
  width: "90%",
});

export const textInput = s({ width: "100%" });

export const checkboxRow = s({
  display: "flex",
  alignItems: "center",
  gap: 8,
  marginLeft: -12,
});

export const submit = s({
  marginBottom: 12,
});

export const error = s({
  color: Styles.colors.error,
  marginBottom: 12,
  fontSize: 14,
  fontWeight: 500,
});

export const topNav = s({
  width: "fit-content",
  margin: "auto",
  border: `1px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "stretch",
  marginBottom: 16,
});

export const topNavItem = s({
  flex: 1,
  textAlign: "center",
  padding: 12,
  borderRight: "1px solid black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});
