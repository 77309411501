import React from "react";
import * as style from "./UpdateBucket.style";
import { classes } from "typestyle";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../../store/actions";
import * as Models from "../../../../models";
import { RootState } from "../../../../store/reducers";
import * as Components from "../../../../components";
import * as Utility from "../../../../utility";
import { toast } from "react-toastify";

interface ComponentProps {
  contestTemplateId: number;
  bucketId: number;
  onSuccess: () => void;
}

interface StateProps {
  bucket: Models.Contests.Bucket;
  matchups: Record<number, Models.Contests.Matchup> | null;
}

export const UpdateBucketInternal: React.FC<ComponentProps & StateProps> = ({
  matchups,
  bucketId,
  contestTemplateId,
  bucket,
  onSuccess,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const matchupsArray = React.useMemo(() => {
    return matchups ? Object.values(matchups) : [];
  }, [matchups]);

  const getMatchups = (skip: number) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        variables: {
          take: 50,
          skip,
          afterEventStartTime: new Date(),
          status: Models.Contests.MatchupStatus.PENDING,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups?.length) {
          dispatch(
            ContestActions.getMatchupsSuccess({
              matchups: res.data.adminMatchups,
            }),
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchups(0);
  }, []);

  /**
   * Values
   */

  const [name, setName] = React.useState(bucket.name);
  const [description, setDescription] = React.useState(bucket.description);
  const [selectionWindowStartsAtUtc, setSelectionWindowStartsAtUtc] =
    React.useState<Date | null>(bucket.selectionWindowStartsAtUtc);
  const [selectionWindowEndsAtUtc, setSelectionWindowEndsAtUtc] =
    React.useState<Date | null>(bucket.selectionWindowEndsAtUtc);
  const [selectionsRequired, setSelectionsRequired] = React.useState(
    bucket.minSelection.toString(),
  );
  const [matchupIdsArray, setMatchupIdsArray] = React.useState(
    bucket.matchups.map((matchup) => matchup.id),
  );

  const onMatchupIdClicked = (matchupId: number) => {
    const idx = matchupIdsArray.indexOf(matchupId);
    const newMatchupIdsArray = [...matchupIdsArray];
    if (idx === -1) {
      newMatchupIdsArray.push(matchupId);
    } else {
      newMatchupIdsArray.splice(idx, 1);
    }
    setMatchupIdsArray(newMatchupIdsArray);
  };

  const [submitError, setSubmitError] = React.useState("");

  const [updateBucketMutation, updateBucketMutationStatus] = useMutation(
    Mutation.UPDATE_BUCKET_MUTATION,
  );

  const onSubmit = () => {
    let hasError = false;
    if (!name) {
      setSubmitError("Bucket must have name");
      hasError = true;
    } else if (!selectionWindowStartsAtUtc || !selectionWindowEndsAtUtc) {
      setSubmitError("Must include window start and end times");
      hasError = true;
    } else if (selectionWindowEndsAtUtc <= selectionWindowStartsAtUtc) {
      setSubmitError("Selection Windows for Buckets must end after they start");
      hasError = true;
    } else if (Number.parseInt(selectionsRequired) < 1) {
      setSubmitError("Min Selection must be at least 0 for all buckets");
      hasError = true;
    }
    matchupIdsArray.forEach((matchup) => {
      if (matchups && selectionWindowEndsAtUtc)
        if (
          matchups[matchup] &&
          new Date(matchups[matchup].eventStartTime) < selectionWindowEndsAtUtc
        ) {
          setSubmitError(
            "Selection window can't end after any matchup start times",
          );
          hasError = true;
        }
    });

    if (!hasError) {
      updateBucketMutation({
        variables: {
          id: bucketId,
          name,
          description,
          selectionWindowStartsAtUtc,
          selectionWindowEndsAtUtc,
          minSelection: Number.parseInt(selectionsRequired),
          maxSelection: Number.parseInt(selectionsRequired),
          preexistingMatchupIds: matchupIdsArray,
        },
      })
        .then((res) => {
          if (res.data.adminUpdateBucket) {
            dispatch(
              ContestActions.bucketUpdateSuccess({
                contestTemplateId,
                bucket: res.data.adminUpdateBucket,
              }),
            );
            toast.success("Successfully updated bucket");
            onSuccess();
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((e) => {
          toast.error(JSON.parse(JSON.stringify(e)).message);
        });
    }
  };

  React.useEffect(() => {
    setName(bucket.name);
    setDescription(bucket.description);
    setSelectionWindowStartsAtUtc(bucket.selectionWindowStartsAtUtc);
    setSelectionWindowEndsAtUtc(bucket.selectionWindowEndsAtUtc);
    setSelectionsRequired(bucket.minSelection.toString());
    setMatchupIdsArray(bucket.matchups.map((matchup) => matchup.id));
  }, [bucket]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        {name}
      </div>

      {bucket && (
        <>
          <div className={style.formWrapper}>
            <div className={style.formSection}>
              <Components.TextInput
                className={style.textInput}
                value={name}
                onChange={setName}
                label="Name"
                autocomplete="off"
              />
              <Components.TextInput
                className={style.textInput}
                value={description}
                onChange={setDescription}
                label="Description"
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={selectionWindowStartsAtUtc}
                onChange={setSelectionWindowStartsAtUtc}
                label="Selection Window Start Time"
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={selectionWindowEndsAtUtc}
                onChange={setSelectionWindowEndsAtUtc}
                label="Selection Window End Time"
              />
              <Components.TextInput
                className={style.textInput}
                value={selectionsRequired}
                onChange={setSelectionsRequired}
                label="Selections Required"
                inputType="number"
              />
            </div>
            <div className={style.formSection}>
              <div className={style.subtitle}>Matchups</div>
              <div className={style.matchupSelectionWrapper}>
                {matchupsArray.map((matchup) => (
                  <div
                    key={matchup.id}
                    className={classes(
                      style.matchup,
                      matchupIdsArray.includes(matchup.id)
                        ? style.selectedMatchup
                        : "",
                    )}
                    onClick={() => onMatchupIdClicked(matchup.id)}
                  >
                    <div>
                      <div>{`${matchup.name}`}</div>
                      <div
                        className={style.matchupDate}
                      >{`${Utility.date.toShortDateAndTimeUTC(
                        new Date(matchup.eventStartTime),
                      )}`}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Components.Button
        className={style.button}
        label={"Update Bucket"}
        onClick={onSubmit}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};

export const UpdateBucket = connect(
  (state: RootState, ownProps: ComponentProps) => ({
    matchups: state.contests.matchups,
    bucket:
      !!state.contests.contestTemplates &&
      !!state.contests.contestTemplates[ownProps.contestTemplateId]
        ? state.contests.contestTemplates[
            ownProps.contestTemplateId
          ].buckets.find((bucket) => bucket.id === ownProps.bucketId) ??
          ({} as Models.Contests.Bucket)
        : ({} as Models.Contests.Bucket),
  }),
)(UpdateBucketInternal);
